/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, RectangleSkeleton } from '@sainsburys-tech/bolt'
import deepcopy from 'lodash.clonedeep'
import loadable from '@loadable/component'
import Category from '../Category/Category'
import { CategoryHelper } from '../../helpers/CategoryHelper'
import ContentHelper from '../../helpers/ContentHelper'
import CategoryListerContentHelper from '../../helpers/CategoryListerContentHelper'
import CategoryListSkeleton from './CategoryListSkeleton'
import ProductListSkeleton from '../ProductList/ProductListSkeleton'
import { categoryTypes } from '../../config'
import SimilarAiPills from '../SimilarAiPills/SimilarAiPills'

import * as Styled from './styles'

const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))

class CategoryList extends React.PureComponent {
  getCategoryList(categories) {
    return categories.map(category => {
      if (CategoryHelper.getCategoryType(category) === categoryTypes.SUBHEADING) {
        return (
          <Styled.CategoryGroup key={category.id}>
            <Styled.CategoryGroupHeader>{category.attributes.name}</Styled.CategoryGroupHeader>
            <ul>{this.getCategoryList(category.attributes.children)}</ul>
          </Styled.CategoryGroup>
        )
      }
      const { name, image, path } = category.attributes
      return <Category id={category.id} imageSrc={image} itemName={name} key={category.id} categoryPath={path} />
    })
  }

  render() {
    const {
      seoArea,
      topLevelCategoryId,
      categoryName,
      categoryChildren,
      dfp,
      showLoader,
      destinationPageType,
      location,
      similarAiLinks
    } = this.props

    const locationState = location.state
    const isLocationStatePlp = locationState && (locationState.type === 'PLP' || locationState.type === 'PLPPLUS')
    const isDestinationPagePlp = destinationPageType === 'PLP' || destinationPageType === 'PLPPLUS'
    const isPlp = isLocationStatePlp || isDestinationPagePlp
    let contentAreas = deepcopy(this.props.contentAreas)
    const cmsAreasPresent = contentAreas.length
    if (cmsAreasPresent < 1) contentAreas = CategoryListerContentHelper.injectFallbackPrimaryMerchArea(contentAreas)
    return (
      <ErrorBoundary>
        <div className='browse'>
          <Styled.CategoryContainer className='xs-row xs-12--negative'>
            {(cmsAreasPresent > 1 || isPlp) && (
              <Styled.Navigation className='xs-hidden lg-3 lg-row'>
                {!showLoader && cmsAreasPresent > 1 ? (
                  <Styled.CategoryListCategories>
                    {this.getCategoryList(categoryChildren, topLevelCategoryId)}
                  </Styled.CategoryListCategories>
                ) : (
                  <Styled.SkeletonWrapper>
                    <RectangleSkeleton height={500} />
                  </Styled.SkeletonWrapper>
                )}
              </Styled.Navigation>
            )}
            <div className={`xs-12 ${cmsAreasPresent > 1 || isPlp ? 'lg-9' : ''}`}>
              {showLoader ? (
                isPlp ? (
                  <ProductListSkeleton />
                ) : (
                  <CategoryListSkeleton isFullSize />
                )
              ) : (
                <CMS>
                  {({ default: FindabilityComponents, components }) => {
                    return (
                      <FindabilityComponents components={components}>
                        <div className='browse__content-area'>
                          {categoryName && (
                            <Styled.CategoryName data-el='category-h1' tabIndex='0'>
                              {categoryName}
                            </Styled.CategoryName>
                          )}
                          {CategoryListerContentHelper.getContentAreas(
                            contentAreas,
                            topLevelCategoryId,
                            categoryChildren,
                            ContentHelper.onFindabilityClick.bind(this),
                            dfp,
                            categoryName
                          )}
                        </div>
                      </FindabilityComponents>
                    )
                  }}
                </CMS>
              )}
              {similarAiLinks && similarAiLinks.length > 0 && <SimilarAiPills similarAiLinksData={similarAiLinks} />}
              <CMS>
                {({ default: FindabilityComponents, components }) => {
                  return (
                    <FindabilityComponents components={components}>
                      <Styled.SeoArea className='xs-row xs-auto--none'>
                        {CategoryListerContentHelper.getContentAreas(seoArea)}
                      </Styled.SeoArea>
                    </FindabilityComponents>
                  )
                }}
              </CMS>
            </div>
          </Styled.CategoryContainer>
        </div>
      </ErrorBoundary>
    )
  }
}

CategoryList.defaultProps = {
  contentAreas: [],
  seoArea: [],
  topLevelCategoryId: '',
  dfp: {},
  categoryName: '',
  destinationPageType: '',
  showLoader: false,
  location: {},
  similarAiLinks: []
}

CategoryList.propTypes = {
  contentAreas: PropTypes.array,
  seoArea: PropTypes.array,
  topLevelCategoryId: PropTypes.string,
  categoryChildren: PropTypes.array.isRequired,
  dfp: PropTypes.object,
  categoryName: PropTypes.string,
  destinationPageType: PropTypes.string,
  showLoader: PropTypes.bool,
  location: PropTypes.object,
  similarAiLinks: PropTypes.array
}

CategoryList.contextTypes = {
  loadingStart: PropTypes.func,
  navigateTo: PropTypes.func,
  updateDestinationPageType: PropTypes.func
}

export default CategoryList
