const themeContract = {
  argos: {
    navigation: {
      marginTop: '25px'
    },
    categoryList: {
      borderColor: 'light'
    }
  },
  habitat: {
    navigation: {
      marginTop: '0px'
    },
    categoryList: {
      borderColor: 'white'
    }
  },
  tu: {
    navigation: {
      marginTop: '25px'
    },
    categoryList: {
      borderColor: 'light'
    }
  }
}

export { themeContract }
