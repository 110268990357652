import React from 'react'
import { ProductCardSkeleton } from '@sainsburys-tech/bolt'
import ContentLoader from 'react-content-loader'
import * as Styled from './styles'

const productClassName = 'xs-block md-row xs-6 sm-6 md-4'

const ProductListSkeleton = () => (
  <>
    <Styled.SkeletonBanner>
      <ContentLoader width='100%' height={360} speed={1} backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
        <rect x='0' y='0' rx='3' ry='3' width='300' height='40' />
        <rect x='0' y='55' rx='0' ry='0' width='100%' height='200' />
        <rect x='0' y='270' rx='0' ry='0' width='100%' height='70' />
      </ContentLoader>
    </Styled.SkeletonBanner>

    <Styled.ProductListSkeleton className='xs-block sm-row xs-12--none'>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
      <Styled.SkeletonWrapper className={productClassName}>
        <ProductCardSkeleton mobileLayout='vertical' />
      </Styled.SkeletonWrapper>
    </Styled.ProductListSkeleton>
  </>
)

export default ProductListSkeleton
