import React from 'react'
import deepcopy from 'lodash.clonedeep'
import loadable from '@loadable/component'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import { contentAreaIds, contentAreaTypes } from '../config'
import { getValue } from '../utils/ConfigProvider'
import CitrusBanners from '../components/CitrusBanners/CitrusBanners'

const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))

class CategoryListerContentHelper {
  static isGridLayoutThree(contentArea) {
    return !!contentArea && contentArea.type === contentAreaTypes.GridLayoutThree
  }

  static injectNavigationComponent(contentArea, categoryChildren, onFindabilityClick, alwaysShowNavigation = false) {
    contentArea.navigation = [
      {
        type: contentAreaTypes.NavigationComponent,
        children: categoryChildren,
        alwaysShowNavigation
      }
    ]
    contentArea.onLinkClick = onFindabilityClick
  }

  static injectGridLayoutThreeComponent(area) {
    area.attributes?.push({ type: contentAreaTypes.GridLayoutThree })
  }

  // Add fallback primary merch area if browse content doesn't exist on CLP
  // Area is used to inject M012 navigation component for all viewports
  static injectFallbackPrimaryMerchArea(contentAreas) {
    const areas = deepcopy(contentAreas)
    areas.push({ id: contentAreaIds.AreaA, fallbackPrimaryMerchArea: true, attributes: [] })
    return areas
  }

  static mergeDfpAreaConfig(area, dfpParams) {
    if (area) {
      return { ...dfpParams, ...area }
    }
  }

  static isDfpArea(area) {
    return area.id === contentAreaIds.Dfp.top || area.id === contentAreaIds.Dfp.bottom
  }

  static getContentAreas(
    contentAreas = [],
    topLevelCategoryId,
    categoryChildren,
    onFindabilityClick,
    dfpParams,
    categoryName
  ) {
    const areas = deepcopy(contentAreas)
    const contentComponents = []
    !!areas &&
      areas.forEach((area, index) => {
        if (!area) {
          return
        }
        // Inject the navigation component into the first area that is not the 'SEO' area
        if (
          index === 0 &&
          area.id !== contentAreaIds.SeoArea &&
          area.attributes &&
          !area.attributes.find(this.isGridLayoutThree)
        ) {
          this.injectGridLayoutThreeComponent(area)
        }
        !!area.attributes &&
          area.attributes.forEach((currentArea, j) => {
            // Inject M012 Navigation component if fallback added or feature toggle is on
            if (
              (area.fallbackPrimaryMerchArea || getValue('features.categoryList.contentAreaNav')) &&
              this.isGridLayoutThree(currentArea)
            ) {
              this.injectNavigationComponent(
                currentArea,
                categoryChildren,
                onFindabilityClick,
                area.fallbackPrimaryMerchArea
              )
            }
            if (currentArea.type) {
              const contentProps = !this.isDfpArea(area)
                ? {
                    ...currentArea,
                    categoryId: topLevelCategoryId,
                    onLinkClick: currentArea.onLinkClick || onFindabilityClick
                  }
                : { ...this.mergeDfpAreaConfig(currentArea, dfpParams), areaId: area.id }
              contentComponents.push(
                <div key={`${j + area.id + categoryName}`} data-area={`${j + area.id}`}>
                  <ErrorBoundary>
                    <CMS>
                      {({ GetComponent }) => {
                        if (this.isDfpArea(area) && getValue('features.citrusBanners.enabled')) {
                          return (
                            <>
                              <CitrusBanners
                                categoryName={categoryName.toLowerCase()}
                                placement='category-landing-argos'
                                clpPosition={area.id === 'DFPTOP' ? 'top' : 'bottom'}
                              />
                            </>
                          )
                        }
                        return <GetComponent {...{ ...currentArea, ...contentProps }} />
                      }}
                    </CMS>
                  </ErrorBoundary>
                </div>
              )
            }
          })
      })
    return contentComponents
  }
}

export default CategoryListerContentHelper
