import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CategoryList from '../../components/CategoryList/CategoryList'
import { dfpSelector } from '../../selectors/content'

const mapStateToProps = state => ({
  similarAiLinks: state.cms.data.similarAiLinks,
  contentAreas: state.cms.data.contentAreas,
  seoArea: state.seo.data.seoArea,
  showLoader: state.application.loader?.show,
  destinationPageType: state.application.destinationPageType,
  dfp: dfpSelector(state)
})

export default withRouter(connect(mapStateToProps)(CategoryList))
