import styled, { css } from 'styled-components'

const CategoryItemWrapper = styled.a`
  flex: 0 0 auto;
  border-left-style: solid;
  border-left-width: 5px;
  position: relative;
  padding: 0 20px 0 15px;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.root};
  line-height: ${props => props.theme.font.stack['body-1']['line-height']};
  text-decoration: none;
  width: 100%;

  @media (max-width: ${props => props.theme.size.breakpoint['max-width'].md}) {
    padding: 0 15px 0 10px;
  }

  :first-child {
    border-radius: 3px 3px 0 0;
  }

  :last-child {
    border-radius: 0 0 3px 3px;
  }

  :not(:last-child) & {
    border-bottom: 1px solid ${props => props.theme.color.monochrome.light};
  }

  ${props =>
    !props.active &&
    css`
      border-left-color: transparent;
      cursor: pointer;
    `}

  ${props =>
    !props.active &&
    css`
      border-left-color: transparent;
      cursor: pointer;
    `}

  ${props =>
    props.isSubHeading &&
    css`
      flex: 0 0 auto;
      margin: 25px 0 0;
      padding: 0 20px;
      width: 100%;
      color: ${props.theme.color.monochrome.darker};
    `}
`

export const CategoryLink = styled.a`
  padding: 20px 0;
  color: ${props => props.theme.color.monochrome.darker};
  width: 100%;

  :hover {
    color: inherit;
    text-decoration: underline;
  }

  /* stylelint-disable-next-line */
  ${CategoryItemWrapper}:not(:last-child) & {
    border-bottom: 1px solid ${props => props.theme.color.monochrome.light};
  }
`

export { CategoryItemWrapper }
