import styled from 'styled-components'
import { CategoryItemWrapper, CategoryLink } from '../Category/styles'
import { themeContract } from './styles.theme.contract'

export const CategoryContainer = styled.div`
  position: relative;
`
export const CategoryListCategories = styled.ul`
  list-style-type: none;
  border-radius: 3px;
  border: 1px solid
    ${props => props.theme.color.monochrome[themeContract[props.theme.channel].categoryList.borderColor]};
  background-color: ${props => props.theme.color.monochrome.white};
  align-self: flex-start;
  width: 100%;
`

export const Navigation = styled.div`
  margin-bottom: 25px;
  margin-top: ${props => themeContract[props.theme.channel].navigation.marginTop};
`

export const SkeletonWrapper = styled.div`
  width: 100%;
`

export const CategoryName = styled.h1`
  margin: 24px 0;
`

export const SeoArea = styled.div`
  margin-top: 15px;
  margin-bottom: 40px;
`
export const CategoryGroup = styled.li`
  width: 100%;
`
export const CategoryGroupHeader = styled.h2`
  flex: 0 0 auto;
  margin: 25px 20px 0;
  width: calc(100% - 40px);
  color: ${props => props.theme.color.monochrome.darker};
  border: 1px solid ${props => props.theme.color.monochrome.light};
  border-width: 0 0 1px;
  display: block;
  padding: 0 0 20px;

  + ul ${CategoryItemWrapper} {
    &:first-child {
      ${CategoryLink} {
        padding-top: 10px;
      }
    }
  }
`
